import { Card, Col, Container, Row } from "react-bootstrap"
import React, { useEffect } from "react"

import { useLocation } from "react-router-dom";

import * as Images from 'img'

const Projects = () => {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const projects = [
        { key: 1, text: "CAD, 3D Print, Software", title: "2-Axis Bottle Plotter", img: Images.CAD1Small, link: "https://github.com/LenardOng/bottle_plotter", tags: "engineering" },
    ]

    const getCard = (card) => {
        return (
            <Col key={card.key} className="project__cards_col p-3">
                <Card className="project__cards">
                    <Card.Img variant="top" src={card.img} />
                    <Card.Body className="project__cards_body">
                        <Card.Title className="project__cards_top">{card.title}</Card.Title>
                        <Card.Text className="project__cards_text">
                            {card.text}
                        </Card.Text>
                        <a href={card.link}>Find out more</a>
                    </Card.Body>
                </Card>
            </Col>
        )
    }

    return (

        <Container className="pb-3 pt-3">
            <h1>Projects</h1>
            <Col className="pt-4 pb-4">
                <Card>
                    <Card.Body>
                        <Card.Text>
                            Below are some of my personal projects in recent years.<br /> Some coding, some engineering, just whatever I wanted to do at that point of time
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Col>

            <Row xs={1} md={2} lg={3} className="g-2 p-4">
                {projects.map(project => { return getCard(project) })}
            </Row>
        </Container>
    )
}

export default Projects;