import { Col, Row } from 'react-bootstrap'

import EmailIcon from '@mui/icons-material/Email';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import React from 'react'

const ContactFooter = () => {
    return (
        <Row>
            <Col>
                <a className='p-2' href="https://www.linkedin.com/in/lenardong/">
                    <LinkedInIcon fontSize="large" className="aboutme__icon" />
                </a>
                <a className='p-2' href="mailto: s.avian@hotmail.com">
                    <EmailIcon fontSize="large" className="aboutme__icon" />
                </a>
                <a className='p-2' href="https://github.com/LenardOng">
                    <GitHubIcon fontSize="large" className="aboutme__icon" />
                </a>
            </Col>
        </Row>
    )

}

export default ContactFooter