import "css/AboutMe.css"

import { Card, Col, Container, Row } from "react-bootstrap"
import React, { useEffect } from "react"

import ContactFooter from "components/ContactFooter";
import { useLocation } from "react-router-dom";

const AboutMe = () => {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const EY_experience = ["Technology and Business Analyst for implementation of a lowcode training management system. " +
        "Responsibilities include solutioning with the technical team, documentation of business and technical requirements, and working with a development team to ensure successful delivery. ",
        "Project Management for ERP implementation. Responsibilities include producing project plans, key stakeholder engagement " +
        "and presentation, and tracking action lists and project risks. ",
        "Analysis of ERP Solutions for a Modern Digital Core. Responsibilities include conducting requirement workshops, communicating with vendors, and producing analytical reports based " +
        "on business requirements."]

    const Shopee_experience = ["Backend Software Engineer in Recommendation Systems. "+
    "I build servers to serve millions of concurrent online users with scalable server architectures. " +
    "Responsibilities include building various platforms to support the fast-growing business, including but not limited to recall engine, ranking engine, and business platform"]
        // "I developed a Go server to provide nearest neighbour results for large scale data retrieval. " +
        // "Ranking server for generating machine learning predictions from features " +
        // "I development of tools and servers for managing data in Elasticsearch. "

    const aboutme = [
        { key: 2, from: "Jun-2020", to: "Current", role: "Software Engineer", company: "Shopee", experience: Shopee_experience, tags: "work" },
        { key: 1, from: "Nov-2018", to: "Jun-2020", role: "Technology Consultant", company: "EY Advisory", experience: EY_experience, tags: "work" }, 

    ]

    const getCard = (card) => {
        return (
            <Col key={card.key} className="aboutme__cards_col p-3">
                <Card className="aboutme__cards" >
                    <Card.Header>{card.from} - {card.to}</Card.Header>
                    <Card.Body className="aboutme__cards_body">
                        <Card.Title className="aboutme__cards_top">{card.role}</Card.Title>
                        <Card.Subtitle className="aboutme__cards_subtitle mb-2">{card.company}</Card.Subtitle>
                        {
                            card.experience.map((row, index) => {
                                return (
                               <Card.Text key={index} className="aboutme__cards_text">
                                    {row}
                                </Card.Text>
                                )
 
                            })
                        }
                    </Card.Body>
                </Card>
            </Col>
        )
    }

    return (
        <Container className="pb-3 pt-3">
            <h1>Work Experience</h1>
            <Row md={1} className="g-2 p-4">
                {aboutme.map(cert => { if (cert.tags.search("work") != -1) { return getCard(cert) } })}
            </Row>



            <ContactFooter />
        </Container>

    )

}

export default AboutMe