/* eslint-disable react/no-unescaped-entities */

import "css/Learning.css"

import * as Images from 'img'

import { Card, Col, Container, Image, Modal, Row } from "react-bootstrap"
import React, { useEffect, useState } from "react"

import { useLocation } from "react-router-dom";

const Learning = () => {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [modalBody, setModalBody] = useState();
    const [modalHeader, setModalHeader] = useState("");

    const certs = [
        { key: 1, text: "Jul-2018", title: "First Class Masters of Engineering Science, Oxford University", img: Images.UniCert, tags: "qual,engineering,2018" },
        { key: 2, text: "Jul-2018", title: "Thesis: Estimating Uncertainty in Deep Visual Odometry, Oxford University", img: Images.Thesis, tags: "qual,engineering,2018", modal: Images.Abstract },
        { key: 3, text: "Sep-2021", title: "Beginning C++ From Beginning to Beyond - 46 hours", img: Images.BeginningCpp, tags: "course,it,2021" },
        { key: 4, text: "May-2020", title: "Spring and Hibernate for Beginners - 42 hours", img: Images.SpringCert, tags: "course,it,2020" },
        { key: 5, text: "Apr-2020", title: "Architecting with Google Compute Engine - 5 Course Specialisation", img: Images.ArchitectingGCP, tags: "course,it,2020" },
        { key: 6, text: "Apr-2020", title: "Data Engineering, Big Data, and Machine Learning on GCP - 5 Course Specialisation", img: Images.DataEngCert, tags: "course,it,2020" },
        { key: 7, text: "Feb-2020", title: "Web Applications for Everybody - 4 Course Specialisation", img: Images.WebAppCert, tags: "course,it,2020" },
        { key: 8, text: "Dec-2019", title: "Cloud Architecture with GCP - 6 Course Specialisation", img: Images.CloudArchitecture, tags: "course,it,2019" },
        { key: 9, text: "Jan-2019", title: "Ethereum and Solidity: The Complete Developer's Guide - 24 hours", img: Images.BlockchainCert, tags: "course,it,2019" },
        { key: 10, text: "Jun-2019", title: "Managerial Accounting: Cost Behaviors, Systems and Analysis", img: Images.Management2, tags: "course,business,2019" },
        { key: 11, text: "Jun-2019", title: "Operations Management: Strategy and Quality Management for the Digital Age", img: Images.Operations2, tags: "course,business,2019" },
        { key: 12, text: "May-2019", title: "Operations Management: Analysis and Improvement Methods", img: Images.Operations1, tags: "course,business,2019" },
        { key: 13, text: "May-2019", title: "Marketing Management I", img: Images.Management1, tags: "course,business,2019" },
        { key: 14, text: "Jan-2019", title: "Fundamentals of Business Analysis - 6 hours", img: Images.FundamentalsBusiness, tags: "course,business,2019" },
        { key: 15, text: "Sep-2018", title: "SQL for Data Science", img: Images.SQLCert, tags: "course,it,2018" },
        { key: 16, text: "Mar-2018", title: "Deep Learning - 5 Course Specialisation", img: Images.DeepLearningCert, tags: "course,it,2018" },
        { key: 17, text: "Mar-2018", title: "Machine Learning", img: Images.MLCert, tags: "course,it,2018" },
        { key: 18, text: "Jan-2019", title: "UI Path Level 1 Developer", img: Images.UIPathL1, tags: "cert,it,2019" },
        { key: 19, text: "Jan-2019", title: "UI Path Level 2 Developer", img: Images.UIPathL2, tags: "cert,it,2019" },
        { key: 20, text: "Dec-2018", title: "Certified Kryon RPA Developer", img: Images.KryonCert, tags: "cert,it,2018" },
    ]

    const getCard = (card) => {
        return (
            <Col key={card.key} className="learning__cards_col p-3">
                <Card className="learning__cards" onClick={() => {
                    if (card.modal) {
                        setModalBody(card.modal)
                    } else {
                        setModalBody(card.img)
                    }
                    setModalHeader(card.title)
                    handleShow();
                }} >
                    <Card.Img variant="top" src={card.img} />
                    <Card.Body className="learning__cards_body">
                        <Card.Title className="learning__cards_top">{card.title}</Card.Title>
                        <Card.Text className="learning__cards_text">
                            {card.text}
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Col>
        )
    }


    return (
        <Container className="pb-3 pt-3">
            <Modal show={show} onHide={handleClose} className="learning__modal">
                <Modal.Header closeButton>
                    {modalHeader}
                </ Modal.Header>
                <Modal.Body><Image className="learning__image" src={modalBody} /></Modal.Body>
            </Modal>


            <h1>Learning</h1>
            <Col className="pt-4 pb-4">
                <Card>
                    <Card.Body>
                        <blockquote className="blockquote mb-0">
                            <p>
                                &ldquo;Once you stop learning, you start dying&rdquo;
                            </p>
                            <footer className="blockquote-footer">
                                <cite title="Albert Einstein">Albert Einstein</cite>
                            </footer>
                        </blockquote>
                        <Card.Text className="pt-4">
                            Below are some of my certificates and accomplishments in recent years
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Col>
            <h3 className='pt-4'>Education</h3>
            <Row xs={1} md={2} lg={3} className="g-2 p-4">
                {certs.map(cert => { if (cert.tags.search("qual") != -1) { return getCard(cert) } })}
            </Row>
            <h3 className='pt-4'>Technical Certs</h3>
            <Row xs={1} md={2} lg={5} className="g-2 p-4">
                {certs.map(cert => { if (cert.tags.search("it") != -1 && cert.tags.search("course") != -1) { return getCard(cert) } })}
            </Row>

            <h3 className='pt-4'>Business Certs</h3>
            <Row xs={1} md={2} lg={5} className="g-2 p-4">
                {certs.map(cert => { if (cert.tags.search("business") != -1) { return getCard(cert) } })}
            </Row>

            <h3 className='pt-4'>Certifications</h3>
            <Row xs={1} md={2} lg={5} className="g-2 p-4">
                {certs.map(cert => { if (cert.tags.search("cert") != -1) { return getCard(cert) } })}
            </Row>
        </Container>
    )

}

export default Learning